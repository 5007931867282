import React, { useMemo } from 'react';
import { Heading, PropertyGroup } from '../../components';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const SectionMultiEnumMaybe = props => {
  const { className, heading, options, selectedOptions } = props;

  if (!options || !selectedOptions) {
    return null;
  }

  const classes = useMemo(() => classNames(css.sectionMultiEnum, className), [className]);

  return (
    <div className={classes}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <PropertyGroup
        id="ListingPage.amenities"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={options.length > 5}
      />
    </div>
  );
};

export default SectionMultiEnumMaybe;
